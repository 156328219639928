import React from 'react';
import PropTypes from 'prop-types';
import ActionMenu from '../../ActionMenu';
import { Tooltip } from '@material-ui/core';

const Menu = ({ items, open, row = [] }) => {
  return (
    <ActionMenu isOpen={open} row={row}>
      {items.map(({ status, action, text, tooltip, placement }) => (
        <ItemMenu
          tooltip={tooltip}
          status={status}
          action={action}
          text={text}
          placement={placement}
        />
      ))}
    </ActionMenu>
  );
};

export const ItemMenu = ({ status, action, text, tooltip, placement }) => {
  return (
    <div
      className={`action-menu__item action-menu__item--${status}`}
      onClick={status === 'disabled' ? null : action}
    >
      {tooltip ? (
        <Tooltip title={tooltip} placement={placement}>
          <span>{text}</span>
        </Tooltip>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

export const MenuContainer = ({
  children,
  onClick,
  isActive,
  icon = 'more_horiz',
  classActive = '',
}) => {
  return (
    <>
      <div className={`action-container ${classActive}`}>
        <span
          className={`material-symbols-outlined icon ${
            isActive && 'icon--active'
          }`}
          onClick={onClick}
        >
          {icon}
        </span>
        {children}
      </div>
    </>
  );
};

Menu.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Menu;
