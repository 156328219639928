import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
    Avatar,
    Box,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';
import {
    Cancel,
    ClearOutlined,
    Edit,
    ExpandMoreOutlined,
} from '@material-ui/icons';
import AvatarUser from './Avatar';

export const InfoMentors = ({ formData, setFormData, pillarsOptions }) => {

    console.log('formData', formData);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    
    const handleChangeChecked = (e) => {
        const { checked } = e.target;
    
        setFormData((prevData) => ({
            ...prevData,
          active: checked, // Actualiza el estado con el nombre y el valor del switch
        }));
    };

    const menteeAdvancedOptions = [
        { id: 'active', value: 'Activo' },
        { id: 'inactive', value: 'Inactivo' },
        { id: 'deactivated', value: 'Desactivado' },
    ];

    const statusRegister = useMemo(() => {
    const filter = menteeAdvancedOptions.filter(
        (item) => item.id === formData.status
    );

    return filter[0].value;
    }, [formData]);

    return (
        <form noValidate autoComplete="off">
            <div className="container-info-mentee">
                <Grid 
                    container
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    spacing={1}
                    className="resumen-mentee"
                >
                    <Grid item xs={4}>
                        <AvatarUser user={formData} />
                    </Grid>
                    <Grid container item xs={8} spacing={5} style={{ rowGap: '24px' }}>
                        <Grid container spacing={1} alignItems="center" xs={12}>
                            <Grid item>
                                <span className="title-resumen">
                                <b>Resumen</b>
                                </span>
                            </Grid>
                            <Grid item>
                                <Chip className="chip-resumen" label={formData.role} />
                            </Grid>
                        </Grid>
                    
                        <Grid container xs={5} style={{ rowGap: '12px' }}>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                <span className="label">
                                    <b>Nombre</b>
                                </span>
                                </Grid>
                                <Grid item xs={12}>
                                <p className="data-label"> {formData.name}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                <span className="label">
                                    <b>Estado de registro</b>
                                </span>
                                </Grid>
                                <Grid item xs={12}>
                                <p className="data-label"> {statusRegister}</p>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container xs={3} style={{ rowGap: '12px' }}>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                <span className="label">
                                    <b>Rut</b>
                                </span>
                                </Grid>
                                <Grid item xs={12}>
                                <p className="data-label"> {formData?.rut}</p>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                <span className="label">
                                    <b>Última conexión</b>
                                </span>
                                </Grid>
                                <Grid item xs={12}>
                                <p className="data-label">{formData.lastSession}</p>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container xs={4} style={{ rowGap: '28px' }}>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                <span className="label">
                                    <b>Fecha de enrolamiento</b>
                                </span>
                                </Grid>
                                <Grid item xs={12}>
                                <p className="data-label">{formData.enrollment}</p>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                                spacing={1}
                            >
                                <FormControlLabel
                                control={
                                    <Switch
                                    size="small"
                                    checked={formData.active}
                                    onChange={handleChangeChecked}
                                    />
                                }
                                label={<b>Usuario activo</b>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider style={{ marginBottom: '4px' }} />
                <Grid container xs={12} spacing={1}>
                    <Grid
                        container
                        item
                        xs={6}
                        direction="column"
                        wrap="nowrap"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                        borderRight: '1px solid #0000001A ',
                        borderBottom: '1px solid #0000001A ',
                        }}
                    >
                        <Grid
                            container
                            item
                            xs={6}
                            style={{ rowGap: '24px', paddingTop: '24px 0', margin: '32px 0' }}
                        >
                            <Grid item xs={12}>
                                <span style={{ fontSize: '18px' }}>
                                <b>Datos personales</b>
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="birthdate"
                                label="Fecha de nacimiento"
                                value={formData.birthdate}
                                onChange={handleChange}
                                placeholder="DD/MM/AAAA"
                                variant="outlined"
                                className="textField-form"
                                fullWidth
                                InputProps={{
                                    style: {
                                    padding: '12px 8px', 
                                    },
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="gender"
                                label="Genero"
                                value={formData.gender}
                                onChange={handleChange}
                                placeholder="Ingrese su genero"
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: {
                                    padding: '12px 8px',
                                    },
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="email"
                                label="E-mail"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="ejemplo@chileconverge.cl"
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: {
                                    padding: '12px 8px', 
                                    },
                                    readOnly: true,
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="phone"
                                label="Teléfono de contacto"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="56912345678"
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: {
                                    padding: '12px 8px',
                                    },
                                    readOnly: true,
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="linkedin"
                                label="Enlace a perfíl de Linkedin"
                                value={formData.linkedin}
                                onChange={handleChange}
                                placeholder="Ingrese su enlace"
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: {
                                    padding: '12px 8px',
                                    autoComplete: 'off',
                                    },
                                }}
                                />
                            </Grid>    
                        </Grid>                       
                    </Grid>

                    <Grid
                        container
                        item
                        xs={6}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            borderBottom: '1px solid #0000001A ',
                            height: 'inherit',
                        }}
                    >
                        <Grid
                            container
                            item
                            xs={6}
                            style={{ rowGap: '24px', margin: '17px 0' }}
                        >
                            <Grid item xs={12}>
                                <span style={{ fontSize: '18px' }}>
                                    <b>Pilares de mentoría</b>
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    className="textField-form"
                                >
                                    {/* <InputLabel id="custom-select-label">País</InputLabel> */}
                                    <Select
                                        name="pillars"
                                        className="select-multiple"
                                        labelId="multiple-select-labe"
                                        value={formData.pillars}
                                        onChange={handleChange}
                                        multiple
                                        renderValue={(selected) => (
                                            <div className={'chips-container'}>
                                                {selected.slice(0, 1).map((value) => {
                                                    const option = pillarsOptions.find(
                                                        (option) => option.id === value
                                                    );
                                                    const label = option?.name;
                                                    console.log('selected', selected.length === 1);
                                                    return (
                                                    <Chip
                                                        key={value}
                                                        label={label}
                                                        className={`chips ${
                                                        selected.length === 1 ? 'more-options' : ''
                                                        }`}
                                                    />
                                                    );
                                                })}
                                                {selected.length > 1 && (
                                                    <Chip
                                                        label={`+ ${selected.length - 1} más`}
                                                        className={'chips more-options'}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        MenuProps={{
                                            classes: { paper: 'selectMenu' },
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            PaperProps: {
                                                style: {
                                                marginTop: 8,
                                                },
                                            },
                                        }}
                                        maxTagCount={1}
                                        IconComponent={ExpandMoreOutlined}                                       
                                    >
                                        {pillarsOptions.map((option) => (
                                            <MenuItem value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>  
                        <Divider style={{ width: '100%' }} /> 
                        <Grid
                            container
                            item
                            xs={6}
                            style={{ rowGap: '24px', paddingTop: '24px 0', margin: '32px 0' }}
                            >
                                <Grid item xs={12}>
                                    <span style={{ fontSize: '18px' }}>
                                    <b>Contraseña</b>
                                    </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="password"
                                        label="Contraseña"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="Contraseña"
                                        variant="outlined"
                                        type="password"
                                        fullWidth
                                        className="textField-form"
                                        InputProps={{
                                            style: {
                                            padding: '12px 8px',
                                            autoComplete: 'new-password',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p style={{ fontSize: '12px', opacity: 0.5 }}>
                                        Las políticas de seguridad para crear una contraseña son:
                                    </p>
                                    <ul>
                                        <li style={{ fontSize: '12px', opacity: 0.5 }}>
                                            De 6 a 8 caracteres.
                                        </li>
                                        <li style={{ fontSize: '12px', opacity: 0.5 }}>
                                            Al menos 2 letras.
                                        </li>
                                        <li style={{ fontSize: '12px', opacity: 0.5 }}>
                                            Al menos 2 números.
                                        </li>
                                    </ul>
                                </Grid>    
                        </Grid>                         
                    </Grid>
                </Grid>
            </div>
        </form>
    );
}