import { useState } from 'react';
import request from '../../../utils/request';
import {
  Cancel,
  Check,
  CheckCircle,
  Clear,
  ExpandMoreOutlined,
} from '@material-ui/icons';
import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from '@material-ui/core';

const EditableCell = ({
  value: initialValue,
  row: { index, original },
  column: { id },
  updateMyData,
  editable = false,
  apiUrl,
  options = [],
}) => {
  const [value, setValue] = useState(initialValue.toString().toLowerCase());
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError('');

      // Send the updated data to the API
      const response = await request(`${apiUrl}/${row.index}`, {
        method: 'PATCH', // You can use PUT/POST depending on your API
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          [id]: value, // Send the updated value
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update data');
      }

      // Once successful, update the table data locally
      updateMyData(index, id, value);

      setIsEditing(false);
    } catch (err) {
      setError('Error saving data');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setValue(initialValue); // Reset to the original value
    setIsEditing(false); // Exit edit mode
    setError('');
  };

  return editable ? (
    isEditing ? (
      <div className="container-editableCell">
        <FormControl variant="outlined" fullWidth className="textField-form">
          <Select
            labelId="custom-select-label"
            value={value}
            onChange={handleChange}
            placeholder="Seleccionar"
            MenuProps={{
              classes: { paper: 'selectMenu' },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              PaperProps: {
                style: {
                  marginTop: 2,
                },
              },
            }}
            IconComponent={ExpandMoreOutlined}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <div className="container-actions-btn">
          <IconButton
            onClick={handleSave}
            disabled={isSaving}
            style={{ marginRight: '8px' }}
          >
            <Check />
          </IconButton>
          <IconButton onClick={handleCancel}>
            <Clear style={{ color: 'red' }} />
          </IconButton>
        </div>
      </div>
    ) : (
      <span
        onClick={() =>
          original.rowGroup?.length > 0 ? '' : setIsEditing(true)
        }
      >
        {initialValue}
      </span>
    )
  ) : (
    initialValue
  );
};

export default EditableCell;
