import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card } from '@material-ui/core';

import ReportsAPI from '../reports-api';
import { PowerBIEmbed } from 'powerbi-client-react';
import { read, utils, writeFile, writeFileXLSX } from 'xlsx';
import { TODAY } from '../../../utils/constants';

// images
import mentees from '../../../assets/images/reports/mentees.png';
import logs from '../../../assets/images/reports/logs.png';
import mentors from '../../../assets/images/reports/mentors.png';
import meetings from '../../../assets/images/reports/meetings.png';
import poll from '../../../assets/images/reports/poll.png';
import power from '../../../assets/images/reports/powerbi.png';

import { GetApp } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Divider, Box, Typography } from '@material-ui/core';
import ReportModal, { ErrorModal } from './reportModal';
import LoadingModal from './reportModal';
import TableSite from '../../../components/common/Table';

const FILES = [
  { name: 'Registro Mentees', img: mentees, fn: () => ReportsAPI.getMentees() },
  {
    name: ' Listado Mentores',
    img: mentors,
    fn: () => ReportsAPI.getMentors(),
  },
  { name: 'Inicios de Sesión', img: logs, fn: () => ReportsAPI.getLogins() },
  {
    name: 'Reuniones',
    img: meetings,
    fn: () => ReportsAPI.requestReport('sessions'),
    new: true,
  },
  {
    name: 'Últimas reuniones del emprendedor',
    img: meetings,
    fn: () => ReportsAPI.getLastSessions(),
  },
  // { name: 'Evaluaciones', img: poll, fn: () => ReportsAPI.getSessionPolls() },
];

const REPORTS = {
  sessions: 'Reuniones',
  mentees: 'Registro Mentees',
  mentors: 'Listado Mentores',
  logins: 'Inicios de Sesión',
  polls: 'Evaluaciones',
};
const ReportsList = () => {
  const [files, setFiles] = useState(FILES);
  const [powerbiToken, setPowerbiToken] = useState(null);
  const [embedUrl, setEmbedUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reports, setReports] = useState([]);
  const [isLoadingReports, setIsLoadingReports] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const columns = [
    {
      Header: 'Fecha',
      accessor: 'createdAt',
      Cell: ({ value }) => <span>{new Date(value).toLocaleString()}</span>,
    },
    {
      Header: 'Tipo',
      accessor: 'type',
      Cell: ({ value }) => <span>{REPORTS[value]}</span>,
    },
    {
      Header: 'Solicitado por',
      accessor: 'createdBy.name',
    },
    {
      Header: 'Estado',
      accessor: 'status',
    },
    {
      Header: '',
      accessor: 'url',
      Cell: ({ value }) =>
        value ? (
          <a
            href={value}
            download
            className={`subtitle ${isLoading && 'subtitle--loading'}`}
            style={{ display: 'block', textAlign: 'center' }}
          >
            <GetApp />
          </a>
        ) : null,
    },
  ];
  const onChangePage = (event, newPage) => {
    setActivePage(newPage);
  };

  const fetchReportLogs = async () => {
    try {
      setIsLoadingReports(true);
      const { data } = await ReportsAPI.getLogs();
      const { data: reportData, page, totalPages } = data;

      setTotalPages(totalPages);
      setReports(reportData);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoadingReports(false);
    }
  };
  useEffect(() => {
    fetchReportLogs();
  }, [pageNumber]);

  const exportFileWithPages = async (file) => {
    try {
      setIsLoading(true);
      const {
        data: { objects },
      } = await file.fn();

      // fetch reports 
      await fetchReportLogs();

      var wb = utils.book_new();
      objects.map((data) => {
        const ws = utils.json_to_sheet(data.info);
        utils.book_append_sheet(wb, ws, data.name);
      });
      writeFileXLSX(wb, `${file.name}-${TODAY}.xlsx`, {
        compression: true,
        cellDates: true,
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="content-panel report-container" style={{ marginTop: '1rem' }}>
        {files.map((file, index) => (
          <Card
            className="report__card"
            key={`file-${index}`}
            style={{ border: file.new ? '1px solid #3f51b5' : 'none' }}
          >
            <img src={file.img} alt="texto" />
            <div className="text-container">
              <span className="title">{file.name}</span>
              <span
                className={`subtitle ${isLoading && 'subtitle--loading'}`}
                onClick={() => exportFileWithPages(file)}
              >
                <GetApp />
                Descargar Reporte
              </span>
            </div>
          </Card>
        ))}
        {/* <Card className="report__card">
          <img src={power} alt="texto" />
          <div className="text-container">
            <span className="title">POWER BI</span>
            <span
              className={`subtitle ${isLoading && 'subtitle--loading'}`}
              onClick={() => {
                ReportsAPI.powerbi().then((res) => {
                  const dataPowerBi = res.data;
                  setPowerbiToken(dataPowerBi.access_token);
                  setEmbedUrl(dataPowerBi.embedUrl);
                });
              }}
            >
              <GetApp />
              Ver Reporte
            </span>
          </div>
        </Card> */}
      </div>
      {/* POWERBI view */}
      {/* {powerbiToken && (
        <div style={{ height: '100vh', padding: 24 }}>
          <PowerBIEmbed
            embedConfig={{
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              embedUrl: embedUrl,
              accessToken: powerbiToken, // Keep as empty string, null or undefined
            }}
            cssClassName="report-style-class"
          />
        </div>
      )} */}
      {/* LOADING MODAL */}
      <LoadingModal open={isLoading} />
      {/* ERROR MODAL */}
      <ErrorModal open={isError} onClose={() => setIsError(false)} />

      <Divider className="divider" style={{ margin: '20px 0' }} />
      {/* crea una tabla usando componentes de TableSite */}
      <Box
        display="flex"
        alignItems="center"
        style={{ cursor: 'pointer !important' }}
        onClick={() => fetchReportLogs()}
        className="user-container"
      >
        <RefreshIcon
          style={{
            cursor: 'pointer !important',
            marginRight: '8px',
            animation: isLoadingReports ? 'spin 2s linear infinite' : 'none',
          }}
        />
        <Typography variant="body2">Actualizar</Typography>
      </Box>
      <div className="user-container">
        <TableSite
          data={reports}
          columns={columns}
          isLoadingData={isLoadingReports}
          tableName="Historial de reportes"
          totalPages={totalPages}
          currentPage={pageNumber}
          onChangePage={onChangePage}
        />
      </div>
    </>
  );
};

export default ReportsList;
