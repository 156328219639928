import React, { useState } from 'react';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '../../common/Button';
import ButtonMui from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import empty from '../../../assets/images/empty.png';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { withStyles } from '@material-ui/core/styles';
import Search from './searchBox';
import { TablePaginationActions } from './TablePaginationActions';
import { TablePagination } from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowDropUp,
  RestoreFromTrash,
} from '@material-ui/icons';
import EmptyCard from '../Cards/emptyCard';

const LIMIT_PER_PAGE = 25;

const EnhancedTable = ({
  columns,
  data,
  isLoadingData,
  currentPage,
  setCurrentPage,
  totalPages,
  isDynamic = false,
}) => {
  const { getTableProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    isDynamic ? data.length : LIMIT_PER_PAGE
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const StyledTableRow = withStyles(() => ({
    root: {
      '&.error': {
        backgroundColor: '#FFF3F2',
      },
      '&.info': {
        backgroundColor: '#F1FCFF',
      },
      '&.success': {
        backgroundColor: '#EBFDF0',
      },
      '&.warning': {
        backgroundColor: '#FFFBEE',
      },
      '&.default': {
        backgroundColor: '#FFFFFF',
      },
    },
  }))(TableRow);

  return (
    <>
      {data.length > 0 ? (
        <TableContainer className="table-container">
          <MaUTable {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell
                        {...(column.id === 'actions'
                          ? column.getHeaderProps()
                          : column.getHeaderProps(
                              column.getSortByToggleProps()
                            ))}
                        style={{
                          textAlign: column.isCentered ? 'center' : 'left',
                          width: column.width,
                          minWidth: column.width,
                        }}
                      >
                        {column.id !== 'actions' ? (
                          <div
                            style={{
                              display: 'flex',
                              gap: '24px',
                            }}
                          >
                            {column.render('Header')}
                            <TableSortLabel
                              active={column.isSorted}
                              className="table-sort-label"
                              // react-table has a unsorted state which is not treated here
                              direction={column.isSortedDesc ? 'desc' : 'asc'}
                              IconComponent={
                                column.isSortedDesc === 'desc'
                                  ? ArrowDropDown
                                  : ArrowDropUp
                              }
                            />
                          </div>
                        ) : (
                          <span>{column.render('Header')}</span>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {isLoadingData
                ? [1, 2, 3, 4, 5].map((number) => (
                    <TableRow
                      key={(
                        number *
                        Math.random() *
                        120 *
                        Math.random() *
                        120
                      ).toString()}
                    >
                      {headerGroups.map((headerGroup) =>
                        headerGroup.headers.map(() => (
                          <TableCell
                            key={Math.floor(
                              Math.random() * 120 * Math.random() * 120
                            ).toString()}
                          >
                            <Skeleton />
                          </TableCell>
                        ))
                      )}
                    </TableRow>
                  ))
                : rows.map((row) => {
                    prepareRow(row);
                    console.log('row', row);
                    return (
                      <StyledTableRow
                        className={`${row.original?.bgColor} ${
                          row.original?.estado ? '' : 'inactive'
                        }`}
                        hover
                        tabIndex={-1}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              style={{
                                width: cell.column.width,
                                minWidth: cell.column.width,
                                textAlign: cell.column.isCentered
                                  ? 'center'
                                  : 'left',
                              }}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              <TableRow>
                <TablePagination
                  labelRowsPerPage="" //"Filas por página:"
                  rowsPerPageOptions={[]} //{[5, 10, 25]}
                  count={totalPages}
                  labelDisplayedRows={() => ''}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={(props) => (
                    <TablePaginationActions
                      props={props}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsLoading={isLoadingData}
                    />
                  )}
                />
              </TableRow>
            </TableBody>
          </MaUTable>
        </TableContainer>
      ) : (
        <div className="empty-tab">
          <EmptyCard message="Actualmente no existen datos" img={empty} />
        </div>
      )}
    </>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loader: PropTypes.bool,
};

export default EnhancedTable;
