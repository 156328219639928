import React, { useEffect, useState } from 'react';
import {
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Divider,
} from '@material-ui/core';
import ModalContent from './ModalContent';
import Button from '../Button';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import SurveyAPI from '../../../content/AnnouncementContainer/survey-api';
import Alert from '../Alert';

export const SurveyModalRelationGroup = ({
  openModal,
  setOpenModal,
  setIsLoading,
  rowSelected,
  setRowSelected,
  setReloadDataTable,
  clientId,
  action,
}) => {
  const [message, setMessage] = useState({ type: '', message: '' });
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [error, setError] = useState(false);

  const closeModal = () => {
    setMessage('');
    setValueInput('');
    setIsLoadingModal(false);
    setOpenModal(false);
    setRowSelected([]);
  };

  const onFinish = async () => {
    setIsLoadingModal(true);
    setIsLoading(true);

    const payload = {
      groupName: valueInput,
      surveyIds: rowSelected,
      action: action,
      clientId: clientId,
    };

    await SurveyAPI.relationGroup(payload)
      .then((response) => {
        if (response.status === 200) {
          setMessage({
            type: 'success',
            message: 'Grupo de encuestas creado correctamente',
          });
          setReloadDataTable((prev) => !prev);
          setIsLoading(true);
          closeModal();
        }
      })
      .catch((error) => {
        setMessage({
          type: 'error',
          message: error.data.message || 'Error al crear grupo de encuestas',
        });
        setDisabledBtn(true);
      })
      .finally(() => {
        setIsLoadingModal(false);
      });
  };

  const onChangeInput = (e) => {
    const value = e.target.value;
    if (value === '') {
      setValueInput(value);
      setError(true);
      setDisabledBtn(true); // Actualiza el valor del input, aunque sea inválido
      return;
    } else {
      setValueInput(value);
      setError(false);
      setDisabledBtn(false);
    }
  };

  return (
    <>
      {message && <Alert type={message.type}>{message.message}</Alert>}
      <Modal
        open={openModal}
        onClose={closeModal}
        className="modal-relation-group"
      >
        <ModalContent
          onClick={isLoadingModal ? '' : closeModal}
          title="Relacionar encuestas"
        >
          <div className="custom_modal">
            <div className="container-input-form">
              <label>Nombre del grupo de encuestas</label>
              <input
                value={valueInput}
                onChange={onChangeInput}
                className={`input-form ${error ? 'error' : ''}`}
                disabled={isLoadingModal}
              />
              {error && (
                <div className="container-error">
                  <span>Ingrese el nombre del grupo</span>
                </div>
              )}
            </div>
          </div>
          <Divider />
          <div className="modal__action-container">
            <Button secondary onClick={closeModal} disabled={isLoadingModal}>
              Cancelar
            </Button>
            <Button
              disabled={disabledBtn}
              loading={isLoadingModal}
              onClick={onFinish}
            >
              Confirmar
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};
