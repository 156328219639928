import React, { useState } from 'react';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '../../common/Button';
import ButtonMui from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { withStyles } from '@material-ui/core/styles';
import Search from './searchBox';
import { TablePaginationActions } from './TablePaginationActions';
import { TablePagination } from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowDropUp,
  RestoreFromTrash,
} from '@material-ui/icons';

// Create an editable cell renderer
const LIMIT_PER_PAGE = 25;

function GlobalFilter({
  preGlobalFilteredRows,
  setGlobalFilter,
  setPage,
  onSearch,
  style,
  value,
  setValue,
}) {
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 600);

  const onClick = (inputvalue) => {
    onSearch(inputvalue);
    setGlobalFilter(inputvalue);
    setPage(1);
  };

  const search = (e) => {
    setValue(e.target.value);
    if (e.target.value === '') {
      onClick(e.target.value);
    }
  };

  return (
    <span>
      <Search
        style={style}
        value={value}
        onChange={(e) => {
          if (onSearch) {
            search(e);
          } else {
            setValue(e.target.value);
            onChange(e.target.value);
            setPage(0);
          }
        }}
        onClick={() => onClick(value)}
      />
    </span>
  );
}

const EnhancedTable = ({
  columns,
  data,
  tableName,
  loader,
  updateMyData,
  skipPageReset,
  onClickButton,
  buttonLabel,
  isLoadingData,
  currentPage,
  setCurrentPage,
  setIsLoading,
  totalPages,
  onSearch,
  resetFilter,
  filter,
  style,
  isDynamic = false,
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    isDynamic ? data.length : LIMIT_PER_PAGE
  );
  const [value, setValue] = useState('');

  const onResetFilter = () => {
    resetFilter();
    setGlobalFilter(undefined);
    setValue('');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const StyledTableRow = withStyles(() => ({
    root: {
      '&.error': {
        backgroundColor: '#FFF3F2',
      },
      '&.info': {
        backgroundColor: '#F1FCFF',
      },
      '&.success': {
        backgroundColor: '#EBFDF0',
      },
      '&.warning': {
        backgroundColor: '#FFFBEE',
      },
      '&.default': {
        backgroundColor: '#FFFFFF',
      },
    },
  }))(TableRow);

  return (
    <>
      <TableContainer className="table-container">
        <div className="actions-table">
          <h1 className="actions-table__title">{tableName}</h1>
          <div className="actions-table__action-container">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              setPage={setCurrentPage}
              onSearch={onSearch}
              style={style}
              setValue={setValue}
              value={value}
            />

            {loader && <CircularProgress color="secondary" />}
            {onClickButton && (
              <Button
                className="actions-table__button button"
                type={'button'}
                onClick={onClickButton}
              >
                {buttonLabel}
              </Button>
            )}
          </div>
        </div>
        <div className="delete-filter-container">
          {Object.values(filter).length > 0 && (
            <ButtonMui
              startIcon={<RestoreFromTrash />}
              className="_btn"
              variant="text"
              onClick={onResetFilter}
            >
              <u>Borrar filtros</u>
            </ButtonMui>
          )}
        </div>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <TableCell
                      {...(column.id === 'actions'
                        ? column.getHeaderProps()
                        : column.getHeaderProps(column.getSortByToggleProps()))}
                      style={{
                        textAlign: column.isCentered ? 'center' : 'left',
                      }}
                    >
                      {column.id !== 'actions' ? (
                        <div
                          style={{
                            display: 'flex',
                            gap: '24px',
                          }}
                        >
                          {column.render('Header')}
                          <TableSortLabel
                            active={column.isSorted}
                            className="table-sort-label"
                            // react-table has a unsorted state which is not treated here
                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                            IconComponent={
                              column.isSortedDesc === 'desc'
                                ? ArrowDropDown
                                : ArrowDropUp
                            }
                          />
                        </div>
                      ) : (
                        <span>{column.render('Header')}</span>
                      )}

                      {/* //   {column.id !== 'actions' ? (
                    //     <TableSortLabel
                    //       active={column.isSorted}
                    //       className="table-sort-label"
                    //       // react-table has a unsorted state which is not treated here
                    //       direction={column.isSortedDesc ? 'desc' : 'asc'}
                    //     />
                    //   ) : null} */}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {isLoadingData
              ? [1, 2, 3, 4, 5].map((number) => (
                  <TableRow
                    key={(
                      number *
                      Math.random() *
                      120 *
                      Math.random() *
                      120
                    ).toString()}
                  >
                    {headerGroups.map((headerGroup) =>
                      headerGroup.headers.map(() => (
                        <TableCell
                          key={Math.floor(
                            Math.random() * 120 * Math.random() * 120
                          ).toString()}
                        >
                          <Skeleton />
                        </TableCell>
                      ))
                    )}
                  </TableRow>
                ))
              : isDynamic
              ? rows.map((row) => {
                  prepareRow(row);
                  {
                  }

                  return (
                    <StyledTableRow
                      className={`${row.original?.bgColor} ${
                        row.original?.estado ? '' : 'inactive'
                      }`}
                      hover
                      tabIndex={-1}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })
              : rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    prepareRow(row);
                    return (
                      <StyledTableRow
                        className={row.original?.bgColor}
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <TableCell {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
            <TableRow>
              <TablePagination
                labelRowsPerPage="" //"Filas por página:"
                rowsPerPageOptions={[]} //{[5, 10, 25]}
                count={isDynamic ? totalPages : rows.length}
                labelDisplayedRows={() => ''}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={(props) => (
                  <TablePaginationActions
                    props={props}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setIsLoading={setIsLoading}
                  />
                )}
              />
              {}
            </TableRow>
          </TableBody>
        </MaUTable>
      </TableContainer>
    </>
  );
};

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loader: PropTypes.bool,
};

export default EnhancedTable;
