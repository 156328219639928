import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';

import * as Yup from 'yup';
import { Input } from '../../../../components/forms';
import InputWrapper from '../../../../components/forms/Input/inputWrapper';
import Button from '../../../../components/common/Button';
import Dropdown from '../../WorkShopCreate/CreationInputs/dropDownInput';

const LinkEditionForm = ({
  onPrev,
  onNext,
  workShopData,
  setWorkShopData,
  pillars,
}) => {
  const [selectedPillar, setSelectedPillar] = useState(workShopData.pillar);

  const validationSchema = Yup.object().shape({
    recordingLink: Yup.string()
      .required('Ingrese el enlace de inscripción')
      .matches(/^https:\/\/.+/, 'El enlace debe comenzar con "https://"')
      .url('Ingrese una URL válida, por ejemplo, "https://"'),
    workshopLink: Yup.string()
      .required('Ingrese el enlace del taller')
      .matches(/^https:\/\/.+/, 'El enlace debe comenzar con "https://"')
      .url('Ingrese una URL válida, por ejemplo, "https://"'),
  });

  /*  useEffect(() => {
    const pillar = pillars.find((p) => p.id === workShopData.pillar);
    setSelectedPillar(pillar);
    
  }, []); */

  const onSubmit = (values) => {
    setWorkShopData({ ...values, pillar: selectedPillar });
    onNext();
  };
  const onClickOptions = (option) => {
    setSelectedPillar(option);
  };
  return (
    <div className="wizard__form wizard__form--full">
      <Formik
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        initialValues={workShopData}
      >
        {({ errors, touched, ...props }) => {
          return (
            <Form>
              <div className="wizard__grid-section">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <InputWrapper
                    id="workshopLink"
                    name="workshopLink"
                    label="Sala del taller"
                    variant="full"
                  >
                    <Input
                      id="workshopLink"
                      label="https://"
                      name="workshopLink"
                      style={{ width: '447px' }}
                      className="input"
                      type="text"
                    />
                  </InputWrapper>
                  <InputWrapper
                    variant="full"
                    id="recordingLink"
                    name="recordingLink"
                    label="Enlace a la grabación del taller"
                  >
                    <Input
                      id="recordingLink"
                      label="https://"
                      name="recordingLink"
                      style={{ width: '447px' }}
                      className="input"
                      type="text"
                    />
                  </InputWrapper>
                  <InputWrapper
                    variant="full"
                    id="pillar_id"
                    name="pillar_id"
                    label="Asignar pilares"
                    description="Si es necesario, puedes agregar el nuevo taller a una categoría. Deja en blanco para omitir esta opción."
                  >
                    <Dropdown
                      onClick={onClickOptions}
                      selectedOption={selectedPillar}
                      options={pillars}
                    />
                  </InputWrapper>
                </div>
                <div className="wizard__action">
                  <Button secondary onClick={onPrev}>
                    Volver
                  </Button>
                  <Button type="submit">Siguiente</Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LinkEditionForm;
