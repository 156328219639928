import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { Delete, Edit, GetApp } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import UsersAPI from '../../UserContainer/user-api';
import { useHistory, useLocation } from 'react-router-dom';
import request from '../../../utils/request';

import useDebounce from '../../../hooks/useDebounce';
import StatusIndicator from '../../../components/common/StatusIndicator';
import { USER_STATUS_CLASSNAMES } from '../../ClientContainer/constants';
import IndicatorContainer from './indicator-tablesite';
import IndicatorTable from './indicator-table';

const LIMIT_PER_PAGE = 25;
const UserContainer = () => {
  const location = useLocation();
  const [showDropdown, setShowDropDown] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('info');
  const [indicators, setIndicators] = useState([]);
  const [activeIndicator, setActiveIndicator] = useState(false);
  const [nextToDueDateClicked, setNextToDueDateClicked] = useState(false);
  const [nextToDueMentors, setNextToDueMentors] = useState(0);

  let timer;
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setfilter] = useState('');
  const debouncedTerm = useDebounce(filter, 1000);
  const [showRoles, setShowRoles] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    timer = setTimeout(function () {
      setMessage('');
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);
  useEffect(() => {
    setMessage(location.state && location.state.message);
    setTypeMessage(location.state && location.state.type);
  }, [location.state]);

  useEffect(() => {
    if (!debouncedTerm) {
      (async () => {
        try {
          setIsLoading(true);
          const { data } = await UsersAPI.getUsers(
            ['mentor'],
            activePage,
            LIMIT_PER_PAGE
          );
          setUsers(data.users);
          setTotalPages(data.totalPages);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
          setShowRoles(false);
        }
      })();
    }
  }, [activePage, debouncedTerm]);

  const onChangePage = (event, newPage) => {
    if (newPage >= 0) {
      setActivePage(newPage);
    }
  };

  useEffect(() => {
    if (debouncedTerm !== '') {
      (async () => {
        try {
          setIsLoading(true);
          const { data } = await UsersAPI.searchUsers('mentor', debouncedTerm);
          setUsers(data.users);
          setTotalPages(1);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [debouncedTerm]);

  useEffect(() => {
    (async () => {
      const { data } = await UsersAPI.fetchIndicators();

      setIndicators(data);
    })();
  }, []);

  const handleDeleteUser = (id) => {
    request({
      url: `/user/delete/${id}`,
      method: 'DELETE',
      private: true,
    })
      .then(({ data }) => {
        setAnchorEl(null);
        setShowDropDown(undefined);
        // history.push({
        //   pathname: `/mentores`,
        //   state: {
        //     message: data.message,
        //     type: 'success',
        //   },
        // });
        setMessage(data.message);
        setTypeMessage('success');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const toggleIndicator = (indicatorType) => {
    switch (indicatorType) {
      case 'totalNextToDueDate':
        setNextToDueDateClicked(!nextToDueDateClicked);
        setActiveIndicator(!activeIndicator);
        break;

      default:
        break;
    }
  };

  const pdfDownload = async (id) => {
    try {
      const { data } = await UsersAPI.fetchPdfUser(id);
      window.open(data.link, '_blank');
    } catch (error) {
      console.error('Error fetching PDF data:', error.message);
    }
  };

  const renderStatus = (status, registro) => {
    switch (status) {
      case 'active':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Activo"
          />
        );
      case 'inactive':
        if (registro !== 0) {
          return (
            <StatusIndicator
              statusClassName={USER_STATUS_CLASSNAMES[status]}
              text="Postulación incompleta"
            />
          );
        } else {
          return (
            <StatusIndicator
              statusClassName={USER_STATUS_CLASSNAMES[status]}
              text="Revisar Postulación"
            />
          );
        }
      case 'rejected':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Desactivado"
          />
        );
      case 'deactivated':
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Desactivado"
          />
        );
      default:
        return (
          <StatusIndicator
            statusClassName={USER_STATUS_CLASSNAMES[status]}
            text="Sin especificar"
          />
        );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({
          row: {
            original: { status, registro },
          },
        }) => renderStatus(status, registro),
      },
      {
        Header: 'Pilar',
        accessor: 'pillar.name',
      },
      {
        Header: '',
        id: 'actions',
        accessor: function fnAccesor({ id, role, status }) {
          return (
            <>
              <IconButton onClick={() => pdfDownload(id)}>
                <GetApp />
              </IconButton>
              <IconButton
                onClick={() => {
                  //history.push('/usuarios/editar', {
                  history.push('/mentores/editar', {   
                    idUser: id,
                    roleType: role.type,
                  });
                }}
              >
                <Edit />
              </IconButton>
              <>
                <IconButton
                  onClick={(e) => {
                    if (showDropdown === id) {
                      setShowDropDown(undefined);
                    } else {
                      handleClick(e);
                      setShowDropDown(id);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
                {showDropdown === id && (
                  <Popover
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!showDropdown}
                    onClose={() => {
                      setAnchorEl(null);
                      setShowDropDown(undefined);
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={2} width="200px">
                      <Typography>
                        ¿Estás seguro que deseas eliminar al usuario?
                      </Typography>
                      <MenuItem
                        style={{ color: 'red', placeContent: 'center' }}
                        onClick={() => {
                          handleDeleteUser(id);
                        }}
                      >
                        Eliminar
                      </MenuItem>
                      <MenuItem
                        style={{ placeContent: 'center' }}
                        onClick={() => {
                          setAnchorEl(null);
                          setShowDropDown(undefined);
                        }}
                      >
                        cancelar
                      </MenuItem>
                    </Box>
                  </Popover>
                )}
              </>
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [showRoles, showDropdown, anchorEl, history, nextToDueDateClicked]
  );

  return (
    <div className="user-container">
      {message && <Alert type={typeMessage}>{message}</Alert>}
      <IndicatorContainer
        indicators={indicators}
        nextToDueDateClicked={nextToDueDateClicked}
        toggleIndicator={toggleIndicator}
        isLoading={isLoading}
        userList={indicators?.userList}
        activePage={activePage}
        totalPages={totalPages}
        users={users}
        nextToDueMentors={nextToDueMentors}
        isDynamic
      />

      {nextToDueDateClicked ? (
        <IndicatorTable
          tableName="Mentores"
          buttonLabel="Agregar usuario nuevo"
        />
      ) : (
        <TableSite
          data={users}
          columns={columns}
          isLoadingData={isLoading}
          tableName="Mentores"
          buttonLabel="Agregar usuario nuevo"
          onClickButton={() => history.push('/usuarios/crear')}
          onChangePage={onChangePage}
          currentPage={activePage}
          totalPages={totalPages}
          isDynamic
          onSearch={(e) => setfilter(e.target.value)}
        />
      )}
    </div>
  );
};

export default UserContainer;
