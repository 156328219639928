import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Stepper from '../../../components/common/stepper';
import { Form, Formik } from 'formik';
import Wizard from '../../ClientContainer/ClientCreationContainer/wizard';
import CourseCreationContainer from './steps/course-creation';
import LinkComponent from '../../../components/common/Link';
import CourseSummary from './steps/course-summary';
import CoursesAPI from '../course-api';
import PillarAPI from '../../PillarContainer/pillar-api';

const MODE = 'create';

const CourseCreation = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [messageAlert, setMessageAlert] = useState('');
  const [pillars, setPillars] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [course, setCourse] = useState({
    name: '',
    estimatedTime: '',
    hash: '',
    pillar: '',
    active: false,
    hasVideo: false,
    hasReading: false,
    hasDownload: false,
    description: '',
    institution: '',
  });
  const courseSteps = [
    {
      title: 'Informacion del curso',
      Component: CourseCreationContainer,
    },
    {
      title: 'Resumen',
      Component: CourseSummary,
    },
  ];
  useEffect(() => {
    if (loading || refresh) {
      setLoading(true);

      PillarAPI.fetchActivePillars()
        .then((response) => {
          setPillars(response.data);
        })
        .catch((e) => {
          setMessageAlert(e);
        });
      CoursesAPI.getInstitution()
        .then((response) => {
          const institutions = response.data.map((institution) => ({
            value: institution.name,
            id: institution.id,
            logo: institution.logo,
          }));
          setInstitutions(institutions);
        })
        .catch((e) => {
          setMessageAlert(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [refresh]);

  const onNext = () => setActiveStep((prev) => prev + 1);
  const onPrev = () => setActiveStep((prev) => prev - 1);

  const handleFormSubmit = (values) => {
    setCourse(values);
    onNext();
  };

  return (
    <>
      {' '}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="client-container">
          <div className="client__back-container">
            <LinkComponent title="Volver" url="/cursos" icon="arrow_back" />
          </div>
          <Wizard title={'Crear nuevo curso'}>
            <div className="wizard__content">
              <div className="wizard__steps-container wizard-padding">
                {courseSteps.map((currentStep, index) => (
                  <Stepper
                    key={index}
                    text={currentStep.title}
                    isActive={index === activeStep}
                    isDone={index < activeStep}
                  />
                ))}
              </div>
              {courseSteps.map((currentStep, index) => {
                const C = currentStep.Component;
                return (
                  index === activeStep && (
                    <C
                      onPrev={onPrev}
                      onNext={onNext}
                      handleFormSubmit={handleFormSubmit}
                      setCourse={setCourse}
                      pillars={pillars}
                      institutions={institutions}
                      setInstitutions={setInstitutions}
                      setRefresh={setRefresh}
                      course={course}
                      isLoading={loading}
                      mode={MODE}
                      key={`step-component-${index}`}
                    />
                  )
                );
              })}
            </div>
          </Wizard>
        </div>
      )}{' '}
    </>
  );
};

export default CourseCreation;
