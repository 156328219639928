import { CircularProgress } from '@material-ui/core';
import React from 'react';

const Spinner = ({ style }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <CircularProgress style={{ color: '#0bb5e7' }} />
    </div>
  );
};

export default Spinner;
