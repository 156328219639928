import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import TableSite from '../../../components/common/Table';
import TableUser from '../../../components/common/TableUser';
import Alert from '../../../components/common/Alert';
import UsersAPI from '../user-api';
import request from '../../../utils/request';
import { useHistory, useLocation } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import Menu, { MenuContainer } from '../../../components/common/Menu';

const LIMIT_PER_PAGE = 25;

const UserContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDropdown, setShowDropDown] = useState();
  const [showRoles, setShowRoles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [typeMessage, setTypeMessage] = useState('info');
  const [activePage, setActivePage] = useState(0);
  const [filter, setFilter] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [refresh, setRefresh] = useState(false);

  // const debouncedTerm = useDebounce(filter, 1000);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (location.state) {
      setMessage(location.state?.message);
      setTypeMessage(location.state?.type);
    }
  }, [location.state]);

  // useEffect(() => {
  //   if (!debouncedTerm) {
  //     (async () => {
  //       try {
  //         setIsLoading(true);
  //         const { data } = await UsersAPI.getUsers(
  //           ['mentee', 'mentor'],
  //           currentPage,
  //           LIMIT_PER_PAGE
  //         );
  //         setUsers(data.users);
  //         setTotalPages(data.totalPages);
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //         setIsLoading(false);
  //         setShowRoles(false);
  //       }
  //     })();
  //   }
  // }, [currentPage, debouncedTerm]);

  const getUsersList = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await UsersAPI.getUsersList(
        ['mentee', 'mentor', 'admin'],
        currentPage,
        LIMIT_PER_PAGE,
        filter
      );
      setUsers(data.users);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setShowRoles(false);
    }
  }, [currentPage, filter]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  useEffect(() => {
    if (searchText !== '') {
      setIsLoading(true);
      setFilter(searchText.toLowerCase());
    }
  }, [searchText]);

  const onChangePage = (event, newPage) => {
    setActivePage(newPage);
  };

  const onMenuClick = (id) => {
    console.log('hice click');
    setIsMenuOpen((prevOpenMenus) => ({
      [id]: !prevOpenMenus[id],
    }));
  };

  const onSearch = (value) => {
    setSearchText(value);
  };

  const resetFilter = () => {
    setIsLoading(true);
    setSearchText('');
    setFilter('');
  };

  // useEffect(() => {
  //   if (debouncedTerm !== '') {
  //     (async () => {
  //       try {
  //         setIsLoading(true);
  //         const { data } = await UsersAPI.searchUsers('', debouncedTerm);
  //         setUsers(data.users);
  //         setTotalPages(1);
  //       } catch (error) {
  //         console.log(error);
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     })();
  //   }
  // }, [debouncedTerm]);

  const handleDeleteUser = (id) => {
    request({
      url: `/user/delete/${id}`,
      method: 'DELETE',
      private: true,
    })
      .then(({ data }) => {
        setAnchorEl(null);
        setShowDropDown(undefined);
        setMessage(data.message);
        setTypeMessage('success');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((error) => {
        console.error(error);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  };

  const TextFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <input
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value || undefined)}
        placeholder={`Buscar...`}
        style={{ width: '100%' }}
      />
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
        isCentered: false,
      },
      {
        Header: 'Email',
        accessor: 'email',
        isCentered: false,
      },
      {
        Header: 'Rol',
        accessor: 'role.name',
        isCentered: false,
      },
      {
        Header: 'Pilar',
        accessor: 'pillar.name',
        isCentered: false,
      },
      {
        Header: 'Acción',
        id: 'actions',
        isCentered: true,
        Cell: ({ row: { original } }, i) => {
          const listItems = [];
          listItems.push(
            {
              text: 'Editar',
              key: 'editar',
              action: () =>
                history.push('/usuarios/editar', {
                  idUser: original.id,
                  roleType: original.role.type,
                }),
            },
            {
              text: <span style={{ color: 'red' }}> Eliminar</span>,
              key: 'eliminar',
              action: (e) => {
                if (showDropdown === original.id) {
                  setShowDropDown(undefined);
                } else {
                  handleClick(e);
                  setShowDropDown(original.id);
                }
              },
            }
          );

          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              <MenuContainer
                classActive={`${
                  isMenuOpen[original.id] ? 'active-action' : ''
                }`}
                onClick={() => onMenuClick(original.id)}
                key={original.id}
              >
                <Menu
                  open={isMenuOpen[original.id] ?? false}
                  items={listItems}
                  id={original.id}
                />
              </MenuContainer>
              {showDropdown === original.id && (
                <Popover
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!showDropdown}
                  onClose={() => {
                    setAnchorEl(null);
                    setShowDropDown(undefined);
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box p={2} width="200px">
                    <Typography>
                      ¿Estás seguro que deseas eliminar al usuario?
                    </Typography>
                    <MenuItem
                      style={{ color: 'red', placeContent: 'center' }}
                      onClick={() => {
                        handleDeleteUser(original.id);
                      }}
                    >
                      Eliminar
                    </MenuItem>
                    <MenuItem
                      style={{ placeContent: 'center' }}
                      onClick={() => {
                        setAnchorEl(null);
                        setShowDropDown(undefined);
                      }}
                    >
                      cancelar
                    </MenuItem>
                  </Box>
                </Popover>
              )}
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [showRoles, showDropdown, anchorEl, history, isMenuOpen]
  );

  return (
    <div className="user-container">
      {message && <Alert type={typeMessage}>{message}</Alert>}
      {/* <TableSite
        data={users}
        columns={columns}
        isLoadingData={isLoading}
        tableName="Usuarios"
        buttonLabel="Agregar usuario nuevo"
        onClickButton={() => history.push('/usuarios/crear')}
        onChangePage={onChangePage}
        currentPage={activePage}
        totalPages={totalPages}
        onSearch={(e) => setfilter(e.target.value)}
        isDynamic
      /> */}
      <TableUser
        data={users}
        columns={columns}
        isLoadingData={isLoading}
        setIsLoading={setIsLoading}
        tableName="Usuarios"
        buttonLabel="Agregar usuario nuevo"
        onClickButton={() => history.push('/usuarios/crear')}
        onChangePage={onChangePage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        filter={filter}
        resetFilter={resetFilter}
        totalPages={totalPages}
        onSearch={onSearch}
        isDynamic
      />
    </div>
  );
};

export default UserContainer;
