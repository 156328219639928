import React from 'react';
import HistorialAPI from './historial-API';

export const getWorkshopsData = async (
  userId,
  currentPage,
  setData,
  setIsLoading,
  setTotalPages,
  setCountRegisters
) => {
  setIsLoading(true);
  console.log('currentPage', currentPage);
  try {
    const { data } = await HistorialAPI.getWorkshopsByUser(
      userId,
      currentPage,
      10
    );
    setData(data.userWorkshopViews);
    setTotalPages(data.totalPages);
    setCountRegisters(data.totalRecords);
  } catch (error) {
    console.log(error);
  }
  setIsLoading(false);
};

export const getCoursesData = async (
  userId,
  currentPage,
  setData,
  setIsLoading,
  setTotalPages,
  setCountRegisters
) => {
  setIsLoading(true);
  try {
    const { data } = await HistorialAPI.getCoursesByUser(
      userId,
      currentPage,
      10
    );
    setData(data.userCoursesViews);
    setTotalPages(data.totalPages);
    setCountRegisters(data.totalRecords);
  } catch (error) {
    console.log(error);
  }
  setIsLoading(false);
};

export const getMentorshipsData = async (
  userId,
  currentPage,
  setData,
  setIsLoading,
  setTotalPages,
  setCountRegisters
) => {
  setIsLoading(true);
  try {
    const { data } = await HistorialAPI.getMentorshipsByUser(
      userId,
      currentPage,
      10
    );

    console.log('data', data);
    // setData(data.userCoursesViews);
    // setTotalPages(data.totalPages);
    // setCountRegisters(data.totalRecords);
  } catch (error) {
    console.log(error);
  }
  setIsLoading(false);
};
