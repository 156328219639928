import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import {
  CalendarTodayRounded,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  HighlightOffOutlined,
} from '@material-ui/icons';
import moment from 'moment';
import { Divider, Button, IconButton } from '@material-ui/core';

const customDayLocalize = (index, format = 'short') => {
  const days = {
    narrow: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    short: ['D', 'L', 'M', 'Mi', 'J', 'V', 'S'],
    abbreviated: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    wide: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
  };
  return days[format]?.[index] || days.short[index];
};

const customEsLocale = {
  ...es,
  options: {
    ...es.options,
    weekStartsOn: 1,
  },
  localize: {
    ...es.localize,
    day: (index, options) => {
      const format = options?.width || 'short';
      return customDayLocalize(index, format);
    },
  },
};

registerLocale('custom-es', customEsLocale);

const CustomHeader = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  changeYear,
}) => {
  const currentYear = monthDate.getFullYear();
  const years = Array.from({ length: 40 }, (_, i) => currentYear - i);
  return (
    <div>
      <div className="custom-header">
        <IconButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <ArrowBackIosOutlined />
        </IconButton>
        <div>
          <span className="month">
            {monthDate?.toLocaleString('es-ES', { month: 'long' })}
          </span>
        </div>
        <IconButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <ArrowForwardIosOutlined />
        </IconButton>
      </div>
      <div>
        <select
          value={currentYear}
          onChange={(e) => changeYear(parseInt(e.target.value, 10))}
          className="year-selector"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <Divider />
    </div>
  );
};

const CustomInput = forwardRef(
  ({ value, onClick, dateRange, setDateRange, setIsOpen, isOpen }, ref) => {
    const [startDate, endDate] = dateRange;
    const [date, setDate] = useState(null);

    useEffect(() => {
      if (startDate === null && endDate === null) {
        setDate(startDate?.toLocaleDateString());
      }
      if (startDate && endDate === null) {
        setDate(startDate?.toLocaleDateString());
      }

      if (startDate && endDate && startDate !== endDate) {
        setDate(
          `${startDate?.toLocaleDateString()} - ${endDate?.toLocaleDateString()}`
        );
      }
      if (startDate && endDate && startDate === endDate) {
        setDate(
          `${startDate?.toLocaleDateString()} - ${endDate?.toLocaleDateString()}`
        );
      }
    }, [dateRange]);

    const onOpen = () => {
      setIsOpen(!isOpen);
    };

    const allClear = (event) => {
      event.stopPropagation();
      setDateRange([null, null]);
      setIsOpen(false);
    };

    return (
      <>
        {}
        {}
        <button className="input-datepicker" onClick={onOpen} ref={ref}>
          <span className={`${date ? 'color-span' : 'black-span'}`}>
            {date || 'Selecciona un rango de fechas'}
          </span>
          <div className="content-btns">
            {date && (
              <IconButton onClick={allClear}>
                <HighlightOffOutlined />
              </IconButton>
            )}
            <CalendarTodayRounded />
          </div>
        </button>
      </>
    );
  }
);

const CustomDays = (day, date, dateRange) => {
  const [startDate, endDate] = dateRange;
  const isRange = startDate && endDate && date > startDate && date < endDate;

  const isSelected =
    startDate && endDate && date === startDate && date === endDate;

  return (
    <div
      className={`day-datepicker ${isRange ? 'day-range' : ''} ${
        isSelected ? 'day-selected' : ''
      }`}
    >
      <span>{day}</span>
    </div>
  );
};

const RangePicker = ({ dateRange, setDateRange }) => {
  const [startDate, endDate] = dateRange;
  const [isOpen, setIsOpen] = useState(false);

  const onChange = (dates) => {
    setDateRange(dates);

    if (dates[1] !== null) {
      setIsOpen(false);
    }
  };

  return (
    <div className="range-picker">
      <DatePicker
        locale="custom-es"
        selected={startDate}
        onChange={(dates) => onChange(dates)}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat="dd/MM/yyyy"
        placeholderText="Selecciona un rango de fechas"
        open={isOpen}
        onClickOutside={() => setIsOpen(false)}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          changeYear,
        }) => (
          <CustomHeader
            monthDate={date}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            changeYear={changeYear}
          />
        )}
        customInput={
          <CustomInput
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        }
        renderDayContents={(day, date) => CustomDays(day, date, dateRange)}
        popperPlacement="bottom"
        calendarStartDay={1}
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
};

export default RangePicker;
