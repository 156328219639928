import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { set } from 'date-fns';

export function TablePaginationActions({
  props,
  currentPage = 1,
  setCurrentPage,
  setIsLoading,
}) {
  const theme = useTheme();
  const [error, setError] = useState(false);
  const [valueInput, setValueInput] = useState(currentPage);
  const { count } = props;

  const handleBackButtonClick = () => {
    setIsLoading(true);
    setCurrentPage((prevPage) => {
      const newPage = prevPage - 1;
      setValueInput(newPage); // Sincroniza el valor del input
      return newPage;
    });
  };

  const handleNextButtonClick = () => {
    setIsLoading(true);
    setCurrentPage((prevPage) => {
      const newPage = prevPage + 1;
      setValueInput(newPage); // Sincroniza el valor del input
      return newPage;
    });
  };

  const onChangePage = (e) => {
    const value = e.target.value;

    if (value === '') {
      setError(false);
      setValueInput(value);
      return;
    }

    if (value === '' || isNaN(value) || value <= 0 || value > count) {
      setError(true);
      setValueInput(value);
      return;
    }

    setError(false);
    setValueInput(value);
    setIsLoading(true);
    setCurrentPage(parseInt(value, 10));
  };

  return (
    <div className="container-pagination">
      <div className="container-pagination-actions">
        <IconButton
          onClick={handleBackButtonClick}
          disabled={currentPage === 1}
          aria-label="Anterior página"
          className="pagination-button"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <div className="pagination">
          <div className="container-input">
            <input
              className={`input ${error ? 'error' : ''}`}
              value={valueInput}
              onChange={onChangePage}
            />
          </div>
          <span> de {count === 0 ? 1 : count}</span>
        </div>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={currentPage === count}
          aria-label="Siguiente página"
          className="pagination-button"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </div>
      {error && (
        <span className="error-message">
          El valor debe ser mayor a 0 y menor o igual al total de páginas
        </span>
      )}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
