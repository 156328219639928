import moment from 'moment';
import request from '../../utils/request';
import { Refresh } from '@material-ui/icons';

function listSurveys(pageNumber, limit) {
  const page = pageNumber ? `&pageNumber=${pageNumber}` : '';
  const limitPerPage = limit ? `?&pageSize=${limit}` : '';
  return request({
    url: `survey/list${limitPerPage}${page}`,
    method: 'GET',
    private: true,
  });
}

function getSurvey(id) {
  return request({
    url: `survey/${id}`,
    method: 'GET',
    private: true,
  });
}
function addSurvey(payload) {
  return request({
    url: 'survey',
    method: 'POST',
    data: payload,
    private: true,
  });
}
function updateSurvey(payload) {
  return request({
    url: `survey`,
    method: 'PATCH',
    private: true,
    data: payload,
  });
}
function getAllSurveys(
  currentPage,
  limit,
  filter = {},
  refresh = false,
  clientId = ''
) {
  const params = new URLSearchParams();

  params.append('page', currentPage);
  params.append('pagesize', limit);
  params.append('refresh', refresh);

  if (Object.values(filter).length > 0) {
    if (filter.searchText) {
      params.append('surveyName', filter.searchText);
    }
    if (filter.dateRange) {
      params.append(
        'startDate',
        moment(filter.dateRange[0]).format('YYYY-MM-DD')
      );
      params.append(
        'endDate',
        moment(filter.dateRange[1]).format('YYYY-MM-DD')
      );
    }
  }

  if (clientId !== '') {
    params.append('clientId', clientId);
  }

  return request({
    url: `survey/zoho/get-all?${params.toString()}`,
    method: 'GET',
    private: true,
  });
}

function newSurvey(clientId, announcementId, campaignId) {
  const payload = {
    clientId,
    announcementId,
    campaignId,
  };
  return request({
    url: 'survey/zoho/new',
    method: 'POST',
    private: true,
    data: payload,
  });
}

function getTypeSurvey() {
  return request({
    url: `survey/types`,
    method: 'GET',
    private: true,
  });
}
function getRolesSurvey() {
  return request({
    url: `survey/directed-to`,
    method: 'GET',
    private: true,
  });
}

const SurveysAPI = {
  getSurvey,
  listSurveys,
  addSurvey,
  updateSurvey,
  getAllSurveys,
  newSurvey,
  getTypeSurvey,
  getRolesSurvey,
};

export default SurveysAPI;
