import locationsAPI from './paises-ciudades-regiones-API';

export const optionsCountry = async () => {
  try {
    const { data } = await locationsAPI.getCountry();
    return data?.map((country) => ({
      value: country.countryName,
      label: country.countryName,
    }));
  } catch (error) {
    console.error('Error fetching countries:', error);
    return [];
  }
};

export const optionsRegion = async (country) => {
  try {
    const { data } = await locationsAPI.getRegion(country);
    return data?.map((country) => ({
      value: country.regionName,
      label: country.regionName,
    }));
  } catch (error) {
    console.error('Error fetching countries:', error);
    return [];
  }
};

export const optionsCities = async (country, region) => {
  try {
    const { data } = await locationsAPI.getCities(country, region);
    return data?.map((country) => ({
      value: country.cityName,
      label: country.cityName,
    }));
  } catch (error) {
    console.error('Error fetching countries:', error);
    return [];
  }
};
