import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTable } from 'react-table';
import MaUTable from '@material-ui/core/Table';
import React, { useState, useMemo } from 'react';
import Menu, { MenuContainer } from '../Menu';
import { withStyles } from '@material-ui/core/styles';

export const RowsExpanded = ({ data, columns, haveRowSelect }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <MaUTable {...getTableProps()}>
      <tbody
        {...getTableBodyProps()}
        style={{ borderTop: '1px solid rgb(11, 181, 231)' }}
      >
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow
              {...row.getRowProps()}
              style={{ backgroundColor: 'rgba(11, 181, 231, 0.04)' }}
            >
              {haveRowSelect && (
                <TableCell
                  padding="checkbox"
                  style={{
                    minWidth: '50px',
                    width: '50px',
                  }}
                />
              )}

              {row.cells.map((cell) => (
                <TableCell
                  {...cell.getCellProps()}
                  style={{
                    width: cell.column.width,
                    minWidth: cell.column.width,
                    // maxWidth: cell.column.width,
                  }}
                >
                  {cell.render('Cell')}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </tbody>
    </MaUTable>
  );
};
