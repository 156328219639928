import request from './request';

function getCountry() {
  return request({
    url: 'locations/countries',
    method: 'GET',
    private: true,
  });
}

function getRegion(countryName) {
  return request({
    url: `locations/countries?countryName=${countryName}`,
    method: 'GET',
    private: true,
  });
}

function getCities(countryName, regionName) {
  return request({
    url: `locations/countries?countryName=${countryName}&regionName=${regionName}`,
    method: 'GET',
    private: true,
  });
}

const locationsAPI = {
  getCountry,
  getRegion,
  getCities,
};

export default locationsAPI;
