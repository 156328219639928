import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../userContext';
import UsersAPI from '../../../user-api';
import Button from '../../../../../components/common/Button';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';

export const DatosAdicionales = ({ setShowAlert, setMessageAlert }) => {
    const [state] = useContext(UserContext);
    const { user } = state;
    const [formData, setFormData] = useState({
        education: user?.education || '',
        profession: user?.profession || '',       
        experience: user?.experience || '',
        mentor: user?.mentor || '',
        motivation: user?.motivation || '',
        economicExperience: user?.economicExperience || '',
        expectations: user?.expectations || '',
        time: user?.expectations || '',
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchExtraData = async () => {
            if (!user?.id) return;
        
            try {
                const response = await UsersAPI.getExtraData(user.id);
                const extraData = response.data.data
        
                const updatedFormData = { 
                    ...formData, 
                    ...Object.fromEntries(
                        Object.entries(extraData).map(([key, value]) => [
                            key, 
                            value !== null && value !== undefined ? value : formData[key],
                        ])
                    ),
                };
        
                if (JSON.stringify(updatedFormData) !== JSON.stringify(formData)) {
                    setFormData(updatedFormData);
                }
            } catch (error) {
                console.error('Error al obtener datos adicionales:', error);
                setMessageAlert({
                    message: 'Error al obtener los datos adicionales del usuario.',
                    type: 'error',
                });
                setShowAlert(true);
            }
        };
        

        fetchExtraData();
    }, [user?.id, setMessageAlert, setShowAlert]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onSubmit = async () => {
        if (!user?.id) return;
    
        const payload = {
            education: formData.education,
            profession: formData.profession,
            experience: formData.experience,
            mentor: formData.mentor,
            motivation: formData.motivation,
            economicExperience: formData.economicExperience,
        };
    
        setIsLoading(true);
        try {
            await UsersAPI.updateExtraData(user.id, payload);
            setMessageAlert({
                message: 'Datos adicionales actualizados exitosamente.',
                type: 'success',
            });
            setShowAlert(true);
        } catch (error) {
            console.error('Error al actualizar datos adicionales:', error);
            setMessageAlert({
                message: 'Error al actualizar los datos adicionales.',
                type: 'error',
            });
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    };

    const onBack = () => {
        history.push('/usuarios');
    };

    return (
    <form noValidate autoComplete="off">
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh', padding: '16px' }}
        >
            <Grid item xs={12} md={8}>
                <div className="container-info-mentee" style={{ maxWidth: '850px', margin: '0 auto' }}>
                    <Grid container spacing={4}>
                    {/* Columna izquierda */}
                    <Grid item xs={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <span style={{ fontSize: '18px' }}>
                                    <b>Datos adicionales</b>
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="education"
                                label="Nivel educacional"
                                value={formData.education}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: { padding: '12px 8px' },
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="profession"
                                label="Profesión"
                                value={formData.profession}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: { padding: '12px 8px' },
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="experience"
                                label="Años de experiencia laboral en cargos de jefatura"
                                value={formData.experience}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: { padding: '12px 8px' },
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="mentor"
                                label="Años de experiencia como mentor"
                                value={formData.mentor}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: { padding: '12px 8px' },
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="motivation"
                                label="¿Cuéntanos por qué te motiva ingresar a Chile Converge y ser mentor?"
                                value={formData.motivation}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                multiline
                                minRows={6}
                                className="textArea-form"
                                InputProps={{
                                    style: { padding: '12px 8px' },
                                }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Columna derecha */}
                    <Grid item xs={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <div style={{ height: '26px' }}></div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="economicExperience"
                                label="Sector económico donde tienes experiencia"
                                value={formData.economicExperience}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: { padding: '12px 8px' },
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="expectations"
                                label="¿Qué otras expectativas tienes con este programa?"
                                value={formData.expectations}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: { padding: '8px 12px', height: 'auto'},
                                }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                name="time"
                                label="¿Cuánto tiempo estás dispuesto a asignar a este programa?"
                                value={formData.time}
                                onChange={handleChange}
                                variant="outlined"
                                fullWidth
                                className="textField-form"
                                InputProps={{
                                    style: { padding: '8px 12px', height: 'auto', textOverflow: 'ellipsis'},
                                }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>

                    {/* Botones de acción */}
                    <Grid container justifyContent="center" spacing={4} style={{ marginTop: '24px' }}>
                        <Grid item>
                            <Button secondary style={{ width: '241px' }} onClick={onBack}>
                                Volver
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button style={{ width: '241px' }} onClick={onSubmit}>
                                Guardar información
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </form>

    );
};