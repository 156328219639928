import { useContext, useState } from 'react';
import { Avatar, IconButton } from '@material-ui/core';
import { EditOutlined } from '@material-ui/icons';

const AvatarUser = ({ user }) => {
    const [avatar, setAvatar] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            setAvatar(e.target.result); // Guarda la URL base64
        };
        reader.readAsDataURL(file);
        }
    };
    return (
        <div style={{ textAlign: 'center' }}>
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <Avatar
            src={avatar}
            style={{
                width: 157,
                height: 157,
                backgroundColor: '#F7F7F7',
                border: '3px solid #DEDEDE',
                color: '#CACACA',
            }}
            alt="Avatar"
            />
            <input
            accept="image/*"
            style={{ display: 'none' }}
            id="avatar-upload"
            type="file"
            onChange={handleImageUpload}
            />
            <label htmlFor="avatar-upload">
            <IconButton
                component="span"
                style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                background: '#fff',
                borderRadius: '50%',
                color: '#0BB5E7',
                boxShadow: '0px 4px 4px 0px #00000040',
                }}
            >
                <EditOutlined />
            </IconButton>
            </label>
        </div>
        </div>
    );
};

export default AvatarUser;
