import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import Button from '../Button';

const AlertPop = ({ open, setOpen, info, setInfo }) => {
  const handleClose = () => {
    setOpen(false);
    setInfo({ title: '', description: '', actions: {} });
  };

  const onClick = () => {
    handleClose();
    if (info?.actions?.onSuccess) {
      info?.actions?.onSuccessClick();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          {info.title}
        </DialogContentText>
        <DialogContentText>{info.description}</DialogContentText>
      </DialogContent>
      {Object.values(info.actions).length > 0 && (
        <DialogActions>
          {info.actions.onCancel && (
            <Button autoFocus onClick={handleClose} secondary>
              Cancelar
            </Button>
          )}
          {info.actions.onSuccess && (
            <Button onClick={onClick}>{info.actions.onSuccess}</Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AlertPop;
