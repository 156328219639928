import request from '../../utils/request';

async function getSurvey(id) {
  const response = await request({
    url: `/survey/${id}`,
    method: 'GET',
    private: true,
  });
  return response;
}
async function getAnnouncementSurvey(id) {
  const response = await request({
    url: `/survey/announcement/${id}`,
    method: 'GET',
    private: true,
  });
  return response;
}
async function listSurvey(pageNumber, pageSize) {
  const response = await request({
    url: '/survey/list',
    method: 'GET',
    params: {
      pageNumber: pageNumber,
      pageSize: pageSize,
    },
    private: true,
  });
  return response;
}

async function createAnnouncementSurvey(announcementId, data) {
  const response = await request({
    url: `/survey/${announcementId}/announcement`,
    method: 'POST',
    data: data,
    private: true,
  });
  return response;
}
async function reportCoursesByAnnouncement(id) {
  const response = await request({
    url: `/survey/announcement/${id}/report`,
    method: 'GET',
    private: true,
  });
  return response;
}
async function getSurveys(id) {
  const response = await request({
    url: `/announcements/${id}/surveys`,
    method: 'GET',
    private: true,
  });
  return response;
}

const getSurveyByUid = async (uid) => {
  return await request({
    url: `/survey/get-by/${uid}`,
    method: 'GET',
    private: true,
  });
};

async function updateSurvey(surveyId, data) {
  const response = await request({
    url: `/survey/${surveyId}`,
    method: 'PATCH',
    private: true,
    data: data,
  });
  return response;
}

async function relationGroup(payload) {
  const response = await request({
    url: `/zoho/create-group-survey`,
    method: 'POST',
    private: true,
    data: payload,
  });
  return response;
}

const SurveyAPI = {
  getSurveyByUid,
  getSurvey,
  getSurveys,
  listSurvey,
  getAnnouncementSurvey,
  createAnnouncementSurvey,
  reportCoursesByAnnouncement,
  updateSurvey,
  relationGroup,
};

export default SurveyAPI;
