import { IconButton } from '@material-ui/core';
import React from 'react';
const Search = ({
  className = 'actions-table__search search-input survey-search',
  onChange,
  onClick,
  value,
  style,
}) => {
  const handleKeyDown = (event, inputValue) => {
    if (event.key === 'Enter') {
      onClick(inputValue);
    }
  };

  return (
    <div className={className} style={style}>
      <input
        placeholder="Buscar"
        value={value}
        onKeyDown={(event) => handleKeyDown(event, value)}
        onChange={(e) => onChange(e)}
      />
      {/* <span
        onClick={onClick}
        className="search-input__icon material-symbols-outlined"
        style={{ cursor: 'pointer' }}
      >
        search
      </span> */}
      <IconButton onClick={() => onClick(value)}>
        <span className="search-input__icon material-symbols-outlined">
          search
        </span>
      </IconButton>
    </div>
  );
};
export default Search;
