import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from '../../../../components/forms';
import { Grid } from '@material-ui/core';

import UsersAPI from '../../user-api';
import Button from '../../../../components/common/Button';

import SessionSection from './sessionSection';
import PillarsSection from './pillarSection';

import { UserContext } from '../userContext';
import UserAutoSave from '../UserAutoSave';
import Select from '../../../../components/forms/Select';
import { InfoMentees } from './Mentees';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { capitalizingPhrase, formatDate } from '../../../../utils/format';
import { useRut } from '../../../../utils/formatRut';
import { InfoMentors } from './Mentor';

const InformationUser = ({ setShowAlert, setMessageAlert }) => {
  const { format } = useRut();
  //getting user info from context
  const [state, dispatch] = useContext(UserContext);
  const { user, pillars: pillarsOptions } = state;
  const history = useHistory();
  const [formDataMentee, setFormDataMentee] = useState({
    name: user?.name ? user?.name : '-',
    rut: user?.rut ? format(user?.rut) : '-',
    enrollment: user?.createdAt
      ? formatDate(parseInt(user?.createdAt, 10), 'DD/MM/YYYY')
      : '-',
    status: user?.status ? user?.status : '-',
    role: user?.role?.type ? user?.role?.type : '-',
    lastSession: user?.lastSession?.createdAt
      ? formatDate(
          parseInt(user?.lastSession?.createdAt, 10),
          'DD/MM/YYYY HH:mm'
        )
      : '-',
    active: user?.active ? user?.active : false,
    birthdate: user?.birthdate
      ? formatDate(parseInt(user?.birthdate, 10), 'DD/MM/YYYY')
      : '-',
    gender: user?.gender ? user?.gender : '',
    email: user?.email ? user?.email : '',
    phone:
      user?.celphone || user?.telephone
        ? user?.celphone || user?.telephone
        : '',
    linkedin: user?.linkedin ? user?.linkedin : '',
    password: '',
    education: user?.education ? user?.education : '',
    disability: user?.disability ? user?.disability : '',
    registroSocial: user?.registroSocial ? user?.registroSocial : '',
    region: user?.region
      ? user?.region === 'Metropolitana de Santiago'
        ? 'Región Metropolitana de Santiago'
        : user?.region
      : '',
    city: user?.city ? capitalizingPhrase(user?.city) : '',
    country: user?.country ? user?.country : '',
    pillars: user?.pillars ? user?.pillars?.map((item) => item.id) : '',
  });

  const [alertPillar, setAlertPillar] = useState(false);

  const addPillar = (pillar) => {
    setAlertPillar(false);
    if (user.role.type === 'mentor') {
      dispatch({ type: 'UPDATE_MENTOR_PILLAR', payload: { pillar } });
    } else {
      //allows to avoid double click for removing pillar from the list
      if (user.pillars.some((p) => pillar.id === p.id)) {
        const filteredList = user.pillars.filter((p) => p.id !== pillar.id);
        dispatch({
          type: 'UPDATE_MENTEE_PILLAR',
          payload: { pillars: filteredList },
        });
      } else {
        dispatch({
          type: 'UPDATE_MENTEE_PILLAR',
          payload: { pillars: [...user.pillars, pillar] },
        });
      }
    }
  };

  const isAdmin = user.role.type === 'manager' && user.role.type === 'admin';

  //it doesn't send "values" since
  // values contains all user data, mixing
  //extra and extradata in the initial state
  // const handleSubmitMentee = ({
  //   email,
  //   status,
  //   password = null,
  //   pillars,
  //   bio = '',
  //   linkedin = '',
  // }) => {
  //   if (!user.pillars.length && status !== 'rejected') {
  //     setAlertPillar(true);
  //   } else {
  //     setAlertPillar(false);
  //     const idPillars = pillars.map((pillar) => pillar.id);
  //     Promise.all([
  //       UsersAPI.updateUser(user.id, {
  //         email,
  //         status,
  //         password,
  //         bio,
  //         linkedin,
  //       }),

  //       UsersAPI.updateMenteePillars(idPillars, user.id),
  //     ])
  //       .then((response) => {
  //         const [userResponse, _] = response;
  //         setMessageAlert({
  //           message: userResponse.data.message,
  //           type: 'success',
  //         });
  //         setShowAlert(true);
  //       })
  //       .catch((e) => {
  //         setMessageAlert({ message: e.data.message, type: 'error' });
  //         setShowAlert(true);
  //       });
  //   }
  // };
  const handleSubmitMentor = ({
    email,
    status,
    pillar = null,
    password = null,
    bio = '',
    linkedin = '',
  }) => {
    let payload = {};
    if (status === 'rejected') {
      payload = {
        email,
        status,
        password,
        bio,
        linkedin,
        pillar: null,
      };
    } else {
      payload = {
        email,
        status,
        password,
        bio,
        linkedin,
        pillar: pillar ? pillar.id : null,
      };
    }
    UsersAPI.updateUser(user.id, payload)
      .then((response) => {
        setMessageAlert({
          message: response.data.message,
          type: 'success',
        });
        setShowAlert(true);
      })
      .catch((e) => {
        setMessageAlert({ message: e.data.message, type: 'error' });
        setShowAlert(true);
      });
  };

  const handleSubmitAdmin = ({ email, status, password = null }) => {
    UsersAPI.updateUser(user.id, {
      email,
      status,
      password,
    })
      .then((response) => {
        setMessageAlert({
          message: response.data.message,
          type: 'success',
        });
        setShowAlert(true);
      })
      .catch((e) => {
        setMessageAlert({ message: e.data.message, type: 'error' });
        setShowAlert(true);
      });
  };
  // const onSubmit = (values) => {
  //   const { email, name, password, linkedin, status, bio } = values;
  //   const { pillar, pillars } = user;

  //   if (user.role.type === 'mentee') {
  //     handleSubmitMentee({
  //       email,
  //       status,
  //       password,
  //       bio,
  //       linkedin,
  //       pillars,
  //     });
  //   } else if (user.role.type === 'mentor') {
  //     handleSubmitMentor({
  //       email,
  //       status,
  //       pillar,
  //       password,
  //       bio,
  //       linkedin,
  //     });
  //   } else {
  //     handleSubmitAdmin({ email, password, bio, linkedin, status });
  //   }
  // };

  const handleSubmitMentee = async (form) => {
    const payload = {
      active: form.active,
      birthdate: form.birthdate,
      disability: form.disability,
      registroSocial: form.registroSocial,
      gender: form.gender,
      region: form.region,
      city: form.city,
      country: form.country,
    };

    await UsersAPI.updateUserNew(user.id, payload)
      .then((response) => {
        const [userResponse, _] = response;
        setMessageAlert({
          message: userResponse.data.message,
          type: 'success',
        });
        setShowAlert(true);
      })
      .catch((e) => {
        setMessageAlert({ message: e.data.message, type: 'error' });
        setShowAlert(true);
      });
  };

  const onSubmit = (values) => {
    if (user.role.type === 'mentee') {
      handleSubmitMentee(formDataMentee);
    } else if (user.role.type === 'mentor') {
      handleSubmitMentor({
        email,
        status,
        pillar,
        password,
        bio,
        linkedin,
      });
    } else {
      handleSubmitAdmin({ email, password, bio, linkedin, status });
    }
  };
  const mentorAdvancedOptions = [
    { id: 'active', value: 'Activo' },
    { id: 'inactive', value: 'Inactivo' },
    { id: 'rejected', value: 'Rechazado' },
    { id: 'deactivated', value: 'Desactivado' },
  ];

  const menteeAdvancedOptions = [
    { id: 'active', value: 'Activo' },
    { id: 'inactive', value: 'Inactivo' },
    { id: 'deactivated', value: 'Desactivado' },
  ];

  const onBack = () => {
    history.push('/usuarios');
  };

  return (
    <>
      {user.role.type === 'mentee' && (
        <InfoMentees
          formData={formDataMentee}
          setFormData={setFormDataMentee}
          pillarsOptions={pillarsOptions}
        />
      )}

      {user.role.type === 'mentor' && (
        <InfoMentors
          formData={formDataMentee}
          setFormData={setFormDataMentee}
          pillarsOptions={pillarsOptions}
        />
      )}

      {user.role.type !== 'mentee' && user.role.type !== 'mentor' && (
        <div className="edit-information-user" style={{ padding: '24px' }}>
          <Formik
            initialValues={user}
            validationSchema={Yup.object({
              name: Yup.string(),
              email: Yup.string().email('Formato de email incorrecto'),
              password: Yup.string(),
              status: Yup.string(),
            })}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ setFieldValue }) => (
              <Form>
                <UserAutoSave context="userInfo" />
                <Grid container>
                  {/* column1  */}
                  <Grid item xs={12} sm={6} lg={6}>
                    {/* user activation */}

                    <div className="activeUser">
                      <Select
                        options={[]}
                        label=""
                        advancedOptions={
                          user.role.type === 'mentee'
                            ? menteeAdvancedOptions
                            : mentorAdvancedOptions
                        }
                        name="status"
                      />
                    </div>

                    <Grid
                      item
                      style={{
                        backgroundColor: 'white',
                      }}
                      className="personal-info"
                    >
                      <Input
                        name="name"
                        disabled
                        placeholder="Nombre"
                        label="Nombre"
                      />
                      <Input name="email" placeholder="Email" label="Email" />
                      <p>
                        <strong>Contraseña</strong>
                        <br />
                        <small style={{ fontStyle: 'italic', color: 'grey' }}>
                          Las políticas de seguridad para crear una contraseña
                          son:
                          <br />- De 6 a 8 caracteres. <br />- Al menos 2
                          letras. <br />- Al menos 2 números.
                        </small>
                      </p>
                      <Input
                        name="password"
                        type="password"
                        label="Contraseña"
                      />
                    </Grid>
                    {!isAdmin && (
                      <Grid item className="role-info biography">
                        <Input
                          name="linkedin"
                          label="Enlace a tu perfil Linkedin"
                          type="text"
                          max={80}
                        />
                        <Input
                          name="bio"
                          placeholder="Biografía"
                          label="Biografía"
                          type="textarea"
                          max={500}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <SessionSection user={user} />
                    {!isAdmin && (
                      <>
                        {/** Pilares **/}
                        <PillarsSection
                          onClick={addPillar}
                          alertPillar={alertPillar}
                        />
                      </>
                    )}
                  </Grid>
                  {/* column2 */}
                  {/** Datos cuenta **/}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <Grid
        container
        item
        xs={12}
        wrap="nowrap"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        style={{
          padding: '24px',
          height: '200px',
          rowGap: '28px',
        }}
      >
        <Grid item>
          <Button secondary style={{ width: '241px' }} onClick={onBack}>
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ width: '241px' }} onClick={onSubmit}>
            Guardar información
          </Button>
        </Grid>
      </Grid>
    </>

    // <div className="edit-information-user">
    //   <h3>Edita información del usuario</h3>
    //   <Formik
    //     initialValues={user}
    //     validationSchema={Yup.object({
    //       name: Yup.string(),
    //       email: Yup.string().email('Formato de email incorrecto'),
    //       password: Yup.string(),
    //       status: Yup.string(),
    //     })}
    //     onSubmit={(values) => onSubmit(values)}
    //   >
    //     {({ setFieldValue }) => (
    //       <Form>
    //         <UserAutoSave context="userInfo" />
    //         <Grid container>
    //           {/* column1  */}
    //           <Grid item xs={12} sm={6} lg={6}>
    //             {/* user activation */}

    //             <div className="activeUser">
    //               <Select
    //                 options={[]}
    //                 label=""
    //                 advancedOptions={
    //                   user.role.type === 'mentee'
    //                     ? menteeAdvancedOptions
    //                     : mentorAdvancedOptions
    //                 }
    //                 name="status"
    //               />
    //             </div>

    //             <Grid
    //               item
    //               style={{
    //                 backgroundColor: 'white',
    //               }}
    //               className="personal-info"
    //             >
    //               <Input
    //                 name="name"
    //                 disabled
    //                 placeholder="Nombre"
    //                 label="Nombre"
    //               />
    //               <Input name="email" placeholder="Email" label="Email" />
    //               <p>
    //                 <strong>Contraseña</strong>
    //                 <br />
    //                 <small style={{ fontStyle: 'italic', color: 'grey' }}>
    //                   Las políticas de seguridad para crear una contraseña son:
    //                   <br />- De 6 a 8 caracteres. <br />- Al menos 2 letras.{' '}
    //                   <br />- Al menos 2 números.
    //                 </small>
    //               </p>
    //               <Input name="password" type="password" label="Contraseña" />
    //             </Grid>
    //             {!isAdmin && (
    //               <Grid item className="role-info biography">
    //                 <Input
    //                   name="linkedin"
    //                   label="Enlace a tu perfil Linkedin"
    //                   type="text"
    //                   max={80}
    //                 />
    //                 <Input
    //                   name="bio"
    //                   placeholder="Biografía"
    //                   label="Biografía"
    //                   type="textarea"
    //                   max={500}
    //                 />
    //               </Grid>
    //             )}
    //           </Grid>
    //           <Grid item xs={12} sm={6} lg={6}>
    //             <SessionSection user={user} />
    //             {!isAdmin && (
    //               <>
    //                 {/** Pilares **/}
    //                 <PillarsSection
    //                   onClick={addPillar}
    //                   alertPillar={alertPillar}
    //                 />
    //               </>
    //             )}
    //           </Grid>
    //           {/* column2 */}
    //           {/** Datos cuenta **/}
    //           <Grid
    //             item
    //             xs={12}
    //             style={{ backgroundColor: 'white' }}
    //             className="submit"
    //           >
    //             <Grid container justify="center">
    //               <Grid item xs={4}>
    //                 <Button type="submit" widthFull>
    //                   {isAdmin ? 'Editar usuario' : 'Aceptar'}
    //                 </Button>
    //               </Grid>
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //       </Form>
    //     )}
    //   </Formik>
    // </div>
  );
};

export default InformationUser;
