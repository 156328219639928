import request from '../../utils/request';

function getMentees() {
  return request({
    url: 'report/mentees',
    method: 'GET',
    private: true,
  });
}
function getMenteesCompany() {
  return request({
    url: 'report/mentees-company',
    method: 'GET',
    private: true,
  });
}
function getMentors() {
  return request({
    url: 'report/mentor',
    method: 'GET',
    private: true,
  });
}
function getHourMentors() {
  return request({
    url: 'report/mentor-hours',
    method: 'GET',
    private: true,
  });
}

function getLogins() {
  return request({
    url: 'report/login',
    method: 'GET',
    private: true,
  });
}
function getLoginMentees() {
  return request({
    url: 'report/login-mentee',
    method: 'GET',
    private: true,
  });
}
function getSessionCanceled() {
  return request({
    url: 'report/session-canceled',
    method: 'GET',
    private: true,
  });
}
function getSessions() {
  return request({
    url: 'report/session',
    method: 'GET',
    private: true,
  });
}
function getLastSessions() {
  return request({
    url: 'report/mentee-last-session',
    method: 'GET',
    private: true,
  });
}
function getSessionPolls() {
  return request({
    url: 'report/session-poll',
    method: 'GET',
    private: true,
  });
}
function getHoursCategory() {
  return request({
    url: 'report/category',
    method: 'GET',
    private: true,
  });
}

function powerbi() {
  return request({
    url: 'report/powerbi',
    method: 'GET',
    private: true,
  });
}

function getLogs() {
  return request({
    url: 'report/logs',
    method: 'GET',
    private: true,
  });
}

function requestReport(type) {
  return request({
    url: 'report/request-report',
    method: 'POST',
    data: { type },
    private: true,
  });
}

const ReportAPI = {
  getMentees,
  getMentors,
  getHourMentors,
  getHoursCategory,
  getLogins,
  getSessionCanceled,
  getLastSessions,
  getSessions,
  getSessionPolls,
  getLoginMentees,
  getMenteesCompany,
  powerbi,
  getLogs,
  requestReport,
};

export default ReportAPI;
