import { IconButton, Modal } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import TableSite from '../../../components/common/Table';
import Alert from '../../../components/common/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import CoursesAPI from '../course-api';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import Button from '../../../components/common/Button';
import ModalContent from '../../../components/common/Modal/ModalContent';

const LIMIT_PER_PAGE = 10;
const CoursesTableContainer = () => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({ type: '', text: '' });

  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [courseToToggle, setCourseToToggle] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const toggleActivationModal = (course) => {
    setCourseToToggle(course);
    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
  };

  const fetchCourses = () => {
    setIsLoading(true);
    CoursesAPI.getCourses(currentPage, LIMIT_PER_PAGE)
      .then((response) => {
        setCourses(
          response.data.cursos.map((course) => ({
            id: course.id,
            name: course.name,
            institution: course.institution,
            pillar: course.pillar.name,
            active: course.active,
            bgColor:
              !course.active || !course.pillar.active ? 'error' : 'default',
          }))
        );
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => {
        setMessage({ type: 'error', text: 'Error al cargar los cursos.' });
      })
      .finally(() => {
        setTimeout(() => {
          setMessage({ type: '', text: '' });
        }, 1500);
        setIsLoading(false);
        setRefresh(false);
      });
  };

  useEffect(() => {
    fetchCourses();
  }, [currentPage, refresh]);

  const onChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const courseActivation = (courseId, newStatus) => {
    CoursesAPI.toggleCourseActivation(courseId, newStatus)
      .then((response) => {
        setIsMenuOpen({ open: false, id: '' });
        setMessage({
          text: `Curso ${newStatus ? 'activado' : 'desactivado'} con éxito.`,
          type: 'success',
        });
        setRefresh(true);
      })
      .catch((error) => {
        setMessage({
          text: 'Error al actualizar el curso.',
          type: 'error',
        });
      });
  };

  const onClick = (course) => {
    setIsMenuOpen((prev) => ({
      open: prev.id === course.id ? !prev.open : true,
      id: course.id,
      course: course,
    }));
  };
  const confirmToggleCourseStatus = () => {
    if (courseToToggle) {
      const newStatus = !courseToToggle.active;
      setOpenModal(false);
      courseActivation(courseToToggle.id, newStatus);
      setCourseToToggle(null);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Institución',
        accessor: ({ institution }) => institution.name,
      },
      {
        Header: 'Pilar',
        accessor: 'pillar',
      },
      {
        Header: '',
        id: 'actions',
        accessor: 'id',
        Cell: ({ row }) => {
          return (
            <MenuContainer onClick={() => onClick(row.original)}>
              {isMenuOpen.id === row.original.id && isMenuOpen.open && (
                <Menu
                  open={true}
                  items={[
                    {
                      text: 'Editar',
                      action: () => {
                        history.push(`/cursos/editar/${row.original.id}`);
                      },
                    },
                    {
                      text: row.original.active ? 'Desactivar' : 'Activar',
                      action: () => toggleActivationModal(row.original),
                    },
                  ]}
                />
              )}
            </MenuContainer>
          );
        },
        disableSortBy: false,
      },
    ],
    [history, isMenuOpen, courses]
  );
  return (
    <div className="user-container">
      {message.text != '' && <Alert type={message.type}>{message.text}</Alert>}

      <TableSite
        data={courses}
        columns={columns}
        currentPage={currentPage}
        totalPages={totalPages}
        onChangePage={onChangePage}
        isLoadingData={isLoading}
        tableName="Cursos"
        buttonLabel="Agregar nuevo curso"
        onClickButton={() => history.push('/cursos/crear')}
        isDynamic
      />

      {openModal && (
        <Modal
          open={openModal}
          onClose={closeModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ModalContent
            title={
              courseToToggle?.active ? 'Desactivar curso' : 'Activar curso'
            }
            onClick={closeModal}
          >
            <div
              style={{
                background: '#fff',
                padding: '20px',
                borderRadius: '8px',
                textAlign: 'center',
                width: '300px',
                outline: 'none',
                position: 'relative',
              }}
            >
              <p>
                ¿Estás seguro que quieres{' '}
                {courseToToggle?.active ? 'desactivar' : 'activar'} el curso?
              </p>

              <div
                style={{
                  paddingTop: '30px',
                  maxWidth: '500px',
                  margin: 'auto',
                }}
                className="wizard__action"
              >
                <Button
                  variant="outlined"
                  secondary
                  widthFull
                  onClick={closeModal}
                >
                  No
                </Button>

                <Button onClick={confirmToggleCourseStatus} widthFull>
                  Si
                </Button>
              </div>
            </div>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default CoursesTableContainer;
