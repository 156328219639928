import request from '../../../../../../utils/request';

function getCoursesByUser(userId, currentPage, limit) {
  const params = new URLSearchParams();

  params.append('pageNumber', currentPage);
  params.append('pageSize', limit);
  return request({
    url: `/courses/views-by-user/${userId}?${params.toString()}`,
    method: 'GET',
    private: true,
  });
}

function getWorkshopsByUser(userId, currentPage, limit) {
  const params = new URLSearchParams();

  params.append('pageNumber', currentPage);
  params.append('pageSize', limit);
  return request({
    url: `/workshop/views-by-user/${userId}?${params.toString()}`,
    method: 'GET',
    private: true,
  });
}

function getMentorshipsByUser(userId, currentPage, limit) {
  return request({
    url: '/user/list-mentorships',
    method: 'POST',
    private: true,
    data: {
      userId,
      pageNumber: currentPage,
      pageSize: limit,
    },
  });
}

const HistorialAPI = {
  getCoursesByUser,
  getWorkshopsByUser,
  getMentorshipsByUser,
};

export default HistorialAPI;
